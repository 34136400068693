import AppImage from '@theme/components/atom/AppImage'
import DetectMobileMixin from '~/mixins/DetectMobileMixin'
import SleepMixin from '@/mixins/SleepMixin'
import SliderCmsContent from '@theme/components/content/SliderCmsContent'
import _ from 'lodash'
import eventDefinitions from '@/events/eventDefinitions'

const SLIDE_TIME = 4000 // Milliseconds
const TIMER_REFRESH_RATE = 60 // Per second

export default {
  components: {
    AppImage,
    SliderCmsContent,
  },
  mixins: [SleepMixin],
  props: {
    banners: {
      type: Array,
      required: true,
    },
    srcsetBreakpointsDefaults: {
      type: Array,
      default(settings = this.$themeSettings) {
        return [
          // Objects in array must be ordered from largest to smallest breakpoint because browsers accept first suitable option.
          { viewportMinWidth: 768, imageWidth: settings.components.Slider.showTabs ? 936 : 1184 },
          { viewportMinWidth: 480, imageWidth: 768 },
          { viewportMinWidth: 320, imageWidth: 480 },
        ]
      },
    },
  },
  data() {
    return {
      start: true,
      previousBanner: this.banners[this.banners.length - 1],
      activeBanner: this.banners[0],
      sentImpressions: [],
      timer: 0,
      interval: null,
      hydrated: false,
    }
  },
  async mounted() {
    this.sendImpression(this.activeBanner)
    await this.timeout(SLIDE_TIME * 2)
    this.timerStart()
    this.hydrated = true
  },
  methods: {
    throttledChangeBanner: _.throttle(function(banner) {
      this.changeBanner(banner)
    }, 200),
    changeBanner(banner) {
      if (banner !== this.activeBanner) {
        this.start = false
        this.previousBanner = this.activeBanner
        this.activeBanner = banner
        this.timer = 0
        this.sendImpression(this.activeBanner)
      }
    },
    clicked(banner) {
      this.$eventBus.$emit(eventDefinitions.PROMOTION.CLICK, {
        promotion: {
          name: banner.title,
        },
      })
    },
    next() {
      const currentIndex = _.findIndex(this.banners, this.activeBanner)
      const nextIndex = (currentIndex + 1) % this.banners.length
      this.changeBanner(this.banners[nextIndex])
    },
    prev() {
      const currentIndex = _.findIndex(this.banners, this.activeBanner)
      const previousIndex = (currentIndex + this.banners.length - 1) % this.banners.length
      this.changeBanner(this.banners[previousIndex])
    },
    sendImpression(banner) {
      if (banner && !this.sentImpressions.includes(banner)) {
        this.sentImpressions.push(banner)
        this.$eventBus.$emit(eventDefinitions.PROMOTION.IMPRESSION, {
          promotion: {
            name: banner.title,
          },
        })
      }
    },
    swipeHandler(direction) {
      if (direction === 'left') {
        this.next()
      }
      if (direction === 'right') {
        this.prev()
      }
    },
    timerStop() {
      clearInterval(this.interval)
      this.interval = null
    },
    timerStart() {
      this.timerStop()
      if (this.banners.length > 1) {
        const tickTime = 1000 / TIMER_REFRESH_RATE
        const timerJump = 100 / (SLIDE_TIME / tickTime)
        this.interval = setInterval(() => {
          this.timer += timerJump
          if (this.timer > 100) {
            this.next()
            this.timerStart()
          }
        }, tickTime)
      }
    },
  },
  computed: {
    srcsetBreakpoints() {
      return _.get(this.$themeSettings, 'components.Slider.image.srcsetBreakpoints').length
        ? this.$themeSettings.components.Slider.image.srcsetBreakpoints
        : this.srcsetBreakpointsDefaults
    },
  },
}
