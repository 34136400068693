import DetectMobileMixin from '~/mixins/DetectMobileMixin'
import Slider from '~/themes/base/components/content/Slider/Slider.js'

export default {
  extends: Slider,
  mixins: [DetectMobileMixin],
  data() {
    return {
      isMobileDetected: false,
    }
  },
}
