<script>
import LazyComponentMixin from '~/mixins/LazyComponentMixin'

export default {
  mixins: [LazyComponentMixin],
  data() {
    return {
      wrapperClass: 'LetsRegisterLazy',
      componentFactory: () => import('./LetsRegister.vue'),
    }
  },
}
</script>

<style lang="scss">
@import '~variables';

.LetsRegisterLazy > .PlaceholderBox {
  min-height: 50vh;

  @include media-breakpoint-up($mobile-desktop-breaking-point) {
    min-height: 406px;
  }
}
</style>
