import { render, staticRenderFns } from "./PageHomepage.vue?vue&type=template&id=967d08ca&"
import script from "./PageHomepage.js?vue&type=script&lang=js&"
export * from "./PageHomepage.js?vue&type=script&lang=js&"
import style0 from "./PageHomepage.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PageHomepage.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fkolorky%2Fcomponents%2Fshop%2FPageHomepage%2FPageHomepage.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports