import { hydrateWhenVisible } from 'vue-lazy-hydration'
import _ from 'lodash'

import InstagramWidget from '@theme/components/shop/InstagramWidget/InstagramWidgetLazy'
import LayoutVerticalSidebar from '@theme/components/shop/LayoutVerticalSidebar'
import LetsRegister from '@theme/components/content/LetsRegister/LetsRegisterLazy'
import PageHomepage from '~/themes/base/components/shop/PageHomepage/PageHomepage.js'

const ReasonsWhy = () => import('@theme/components/content/ReasonsWhy')
const SmallBanner = () => import('@theme/components/content/SmallBanner')
const Slider = () => import('@theme/components/content/Slider')
const Testimonial = () => import('@theme/components/content/Testimonial')

export default {
  components: {
    InstagramWidget,
    LayoutVerticalSidebar,
    LetsRegister,
    ReasonsWhy: hydrateWhenVisible(ReasonsWhy),
    Slider: hydrateWhenVisible(Slider),
    SmallBanner: hydrateWhenVisible(SmallBanner),
    Testimonial: hydrateWhenVisible(Testimonial),
  },
  mixins: [PageHomepage],
  props: {
    smallBanners: Array,
    testimonials: Array,
  },
  data() {
    return {
      sortedTestimonials: [],
    }
  },
  computed: {
    criticalContentBreakpoints() {
      return _.get(this.$themeSettings, 'components.Slider.image.srcsetBreakpoints').length
        ? this.$themeSettings.components.Slider.image.srcsetBreakpoints
        : this.srcsetBreakpointsDefaults
    },
    criticalContentImageRatio() {
      const width = this.$themeSettings.components.Slider.image.width
      const height = this.$themeSettings.components.Slider.image.height
      return 1 / (width / height)
    },
  },
  mounted() {
    this.sortedTestimonials = _.orderBy(this.testimonials, ['weight'], ['desc'])
  },
}
